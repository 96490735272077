import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  // eslint-disable-next-line jsx-a11y/alt-text
  return <img src="/images/users-1.svg" {...props} alt="images" />;
};

export default Icon;
