import { useEffect, useState } from 'react'

export default function useGetTotalIndex() {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [totalTransactions, setTotalTransaction] = useState<number>(0)
  const [totalNewUser, setTotalNewUser] = useState<number>(0)

  useEffect(() => {
    setIsLoading(true)
    fetch('https://subgraph.poseiswap.xyz/api/totalIndex')
      .then((res) => res.json())
      .then(
        ({
          data: {
            result: { totalTransactions: _totalTransactions, totalNewUser: _totalNewUser },
          },
        }) => {
          setIsLoading(false)
          setTotalTransaction(_totalTransactions)
          setTotalNewUser(_totalNewUser)
        },
      )
      .catch((err) => {
        setIsLoading(false)
        console.error(err)
      })
  }, [])

  return { isLoading, totalTransactions, totalNewUser }
}
