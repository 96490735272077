import { useTranslation } from '@pancakeswap/localization'
import { PageSection, useMatchBreakpoints } from '@pancakeswap/uikit'
import Container from 'components/Layout/Container'
import { PageMeta } from 'components/Layout/Page'
import { useActiveChainId } from 'hooks/useActiveChainId'
import useTheme from 'hooks/useTheme'
import styled from 'styled-components'
import { useAccount } from 'wagmi'
import Footer from './components/Footer'
import Hero from './components/Hero'
import MetricsSection from './components/MetricsSection'
import SalesSection from './components/SalesSection'
import { cakeSectionData, earnSectionData, swapSectionData } from './components/SalesSection/data'
import SwapSection from './components/SwapSection'

const StyledHeroSection = styled(PageSection)`
  padding-top: 16px;

  ${({ theme }) => theme.mediaQueries.md} {
    padding-top: 48px;
  }
`

const BgWrapper = styled.div<{ background?: string }>`
  background-image: ${({ background }) => background || 'none'};
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`

const UserBannerWrapper = styled(Container)`
  z-index: 1;
  position: absolute;
  width: 100%;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  padding-left: 0px;
  padding-right: 0px;

  ${({ theme }) => theme.mediaQueries.lg} {
    padding-left: 24px;
    padding-right: 24px;
  }
`

const Home: React.FC<React.PropsWithChildren> = () => {
  const { theme } = useTheme()
  const { address: account } = useAccount()
  const { chainId } = useActiveChainId()
  const { isMobile, isDesktop } = useMatchBreakpoints()

  const HomeSectionContainerStyles = { marginTop: '30px', width: '100%', maxWidth: '968px', zIndex: 2 }

  const { t } = useTranslation()

  // @ts-ignore
  // @ts-ignore
  return (
    <>
      <PageMeta />
      <style jsx global>
        {`
          // #home-1 .page-bg {
          //   background: linear-gradient(139.73deg, #e6fdff 0%, #f3efff 100%);
          // }
          // [data-theme='dark'] #home-1 .page-bg {
          //   background: #000617;
          // }
          // #home-2 .page-bg {
          //   background: linear-gradient(180deg, #ffffff 22%, #d7caec 100%);
          // }
          // [data-theme='dark'] #home-2 .page-bg {
          //   background: linear-gradient(179.82deg, #000617 16.95%, #011d25 99.85%);
          // }
          // #home-3 .page-bg {
          //   background: linear-gradient(180deg, #6fb6f1 0%, #eaf2f6 100%);
          // }
          // [data-theme='dark'] #home-3 .page-bg {
          //   background: linear-gradient(180deg, #0b4576 0%, #091115 100%);
          // }
          // #home-4 .inner-wedge svg {
          //   fill: #d8cbed;
          // }
          // [data-theme='dark'] #home-4 .inner-wedge svg {
          //   fill: #201335;
          // }
          #footer {
            /* The image used */
            background-image: url('images/weapon3.png');

            /* Full height */
            width: 100%;

            /* Center and scale the image nicely */
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
          }
        `}
      </style>
      <BgWrapper background="url(images/poseidon-background-1920.png), linear-gradient(179.79deg, rgba(0, 6, 23, 0.65) 1.82%, #011D25 99.82%)">
        <StyledHeroSection
          innerProps={{ style: { marginTop: '30px', width: '100%', paddingBottom: 0 } }}
          containerProps={{
            id: 'home-1',
          }}
          index={2}
          hasCurvedDivider={false}
        >
          {/* {account && chainId === ChainId.BSC && (
            <UserBannerWrapper>
              <UserBanner />
            </UserBannerWrapper>
          )} */}
          {/* <MultipleBanner /> */}
          <Hero />
        </StyledHeroSection>
        <PageSection
          innerProps={{ style: { marginTop: '30px', width: '100%', paddingTop: 0 } }}
          containerProps={{
            id: 'home-2',
          }}
          index={2}
          hasCurvedDivider={false}
        >
          <MetricsSection />
        </PageSection>
        <PageSection
          innerProps={{
            style: {
              ...HomeSectionContainerStyles,
              background: 'linear-gradient(267.79deg, rgba(41, 128, 185, 0.4) 9.26%, rgba(44, 62, 80, 0.4) 109.26%)',
              display: 'flex',
              overflow: 'hidden',
              padding: 0,
              marginBottom: 40,
            },
            borderRadius: [0, 0, 0, 0, 32],
          }}
          containerProps={{
            id: 'home-4',
          }}
          index={2}
          hasCurvedDivider={false}
        >
          {/* <OuterWedgeWrapper>
            <InnerWedgeWrapper top>
              <WedgeTopLeft />
              </InnerWedgeWrapper>
          </OuterWedgeWrapper> */}
          {/* <SalesSection {...swapSectionData(t, isMobile)} /> */}
          <SwapSection {...swapSectionData(t, isMobile)} />
        </PageSection>
      </BgWrapper>
      <BgWrapper background="url(images/trident-background-1920.png), linear-gradient(179.77deg, #011D25 -7.14%, #010E1C 99.8%)">
        <PageSection innerProps={{ style: HomeSectionContainerStyles }} index={2} hasCurvedDivider={false}>
          {/* <OuterWedgeWrapper>
            <InnerWedgeWrapper width="150%" top>
              <WedgeTopRight />
              </InnerWedgeWrapper>
          </OuterWedgeWrapper> */}
          <SalesSection {...earnSectionData(t)} />
          {/* TODO: until we are enable fetch multi-chain farms */}
          {/* {chainId === ChainId.BSC && <FarmsPoolsRow />} */}
        </PageSection>
        {/* <PageSection */}
        {/*  innerProps={{ style: HomeSectionContainerStyles }} */}
        {/*  containerProps={{ */}
        {/*    id: 'home-3', */}
        {/*  }} */}
        {/*  index={2} */}
        {/*  hasCurvedDivider={false} */}
        {/* > */}
        {/*  <WinSection /> */}
        {/* </PageSection> */}
        <PageSection innerProps={{ style: HomeSectionContainerStyles }} index={2} hasCurvedDivider={false}>
          <SalesSection {...cakeSectionData(t, isMobile)} />
          {/* <CakeDataRow /> */}
        </PageSection>
      </BgWrapper>
      <PageSection
        containerProps={{
          id: 'footer',
        }}
        innerProps={{ style: { ...HomeSectionContainerStyles, paddingTop: '0', paddingBottom: '0', marginTop: '0' } }}
        index={2}
        hasCurvedDivider={false}
        style={{ backdropFilter: 'blur(20px)', WebkitBackdropFilter: 'blur(20px)' }}
        background="linear-gradient(267.79deg, rgba(0, 43, 126, 0.4) 0%, rgba(0, 47, 45, 0.4) 100%);"
      >
        <Footer />
      </PageSection>
    </>
  )
}

export default Home
