import styled from 'styled-components'
import { ImageAttributes, ImageWrapperStyle } from './CompositeImage'

const ImageWrapper = styled.div<{ width: number; height: number }>`
  position: relative;
  align-self: center;
  max-width: ${({ width }) => width}px;
  max-height: ${({ height }) => height}px;
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 50%;
    padding: 3px;
    background: linear-gradient(to bottom, #15d4b1, #ffc040);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`

const InnerImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`

const RoundImageWithBorder = ({
  imageWrapperStyle,
  image,
  ...props
}: {
  imageWrapperStyle: ImageWrapperStyle
  image: ImageAttributes
}) => {
  return (
    <ImageWrapper {...imageWrapperStyle} {...props}>
      <InnerImg {...image} />
    </ImageWrapper>
  )
}

export default RoundImageWithBorder
