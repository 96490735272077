import { TranslateFunction } from '@pancakeswap/localization'
import { SalesSectionProps } from '.'
import RoundImageWithBorder from '../RoundImageWithBorder'
import FilledImageFitOnTop from '../FilledImage'

export const swapSectionData = (t: TranslateFunction, hideImage: boolean): SalesSectionProps => ({
  headingText: [t('No Registration? No Problem.')],
  bodyText: t('PoseiSwap is a permissionless platform where everyone is allowed aboard. Swap any supported token simply by connecting your wallet.'),
  reverse: false,
  reverseDefault: 'row',
  primaryButton: {
    to: '/swap',
    text: t('Trade Now'),
    external: false,
  },
  secondaryButton: {
    to: 'https://docs.pancakeswap.finance/',
    text: t('Learn'),
    external: true,
  },
  // images: {
  //   path: '/images/home/trade/',
  //   attributes: [
  //     { src: 'BNB', alt: t('BNB token') },
  //     { src: 'BTC', alt: t('BTC token') },
  //     { src: 'CAKE', alt: t('POSE token') },
  //   ],
  // },
  imageComponent: <FilledImageFitOnTop src="/images/poseidon3.png" alt="image" height={330} borderRadius="32px" />,
  hideImage,
})

export const earnSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Sail the Currents of Passive Income.'),
  bodyText: t('Let your assets work for you through staking or supplying liquidity to deepen the PoseiSwap sea.'),
  reverse: true,
  primaryButton: {
    // to: '/farms',
    text: t('Coming soon'),
    external: false,
    notShow: true
  },
  secondaryButton: {
    to: 'https://docs.pancakeswap.finance/products/yield-farming',
    text: t('Learn'),
    external: true,
    notShow: true
  },
  // images: {
  //   path: '/images/home/earn/',
  //   attributes: [
  //     { src: 'pie', alt: t('Pie chart') },
  //     { src: 'stonks', alt: t('Stocks chart') },
  //     { src: 'folder', alt: t('Folder with cake token') },
  //   ],
  // },
  // singleImage: {
  //   src: '/images/poseidon2.png',
  //   alt: 'image',
  //   // width: 303,
  //   // height: 354,
  // },
  // singleImageWrapperStyle: {
  //   width: 279,
  //   height: 279,
  // },
  imageComponent: (
    <RoundImageWithBorder
      imageWrapperStyle={{ width: 279, height: 279 }}
      image={{ src: '/images/poseidon2.png', alt: 'image' }}
    />
  ),
})

export const cakeSectionData = (t: TranslateFunction, hideImage: boolean): SalesSectionProps => ({
  headingText: t('Vote with $POSE.'),
  bodyText: t(
    '$POSE is more than a token for democratic governance. It’s the heart of the ecosystem, allowing you to farm, stake, earn, and win special bonuses.',
  ),
  reverse: false,
  primaryButton: {
    to: '/swap?outputCurrency=0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82&chainId=56',
    text: t('Buy POSE'),
    external: false,
    notShow: true,
  },
  secondaryButton: {
    to: 'https://docs.pancakeswap.finance/tokenomics/cake',
    text: t('Learn'),
    external: true,
    notShow: true,
  },

  // images: {
  //   path: '/images/home/cake/',
  //   attributes: [
  //     { src: 'bottom-right', alt: t('Small 3d PoseiSwap') },
  //     { src: 'top-right', alt: t('Small 3d PoseiSwap') },
  //     { src: 'coin', alt: t('POSE token') },
  //     { src: 'top-left', alt: t('Small 3d PoseiSwap') },
  //   ],
  // },

  // singleImage: {
  //   src: '/images/poseidon1.png',
  //   alt: 'image',
  //   // height: 615,
  //   // width: 615,
  // },
  // singleImageWrapperStyle: {
  //   width: 279,
  //   height: 279,
  // },

  imageComponent: (
    <RoundImageWithBorder
      imageWrapperStyle={{ width: 279, height: 279 }}
      image={{ src: '/images/poseidon1.png', alt: 'image' }}
    />
  ),
  hideImage,
})
