import {
  Flex,
  Text,
  Button,
  Link,
  NextLinkFromReactRouter as RouterLink,
  OpenNewIcon,
  useMatchBreakpoints,
} from '@pancakeswap/uikit'
import Image from 'next/legacy/image'
import styled from 'styled-components'
import { ReactElement, ReactNode } from 'react'
import CompositeImage, { CompositeImageProps, ImageAttributes, ImageWrapperStyle } from '../CompositeImage'
import ColoredWordHeading from '../ColoredWordHeading'
import RoundImageWithBorder from '../RoundImageWithBorder'
import FilledImageFitOnTop from '../FilledImage'

interface SalesSectionButton {
  to?: string
  text: string
  external: boolean
  notShow?: boolean
}

export interface SalesSectionProps {
  headingText: string | string[]
  bodyText: string
  reverse: boolean
  primaryButton: SalesSectionButton
  secondaryButton: SalesSectionButton
  images?: CompositeImageProps
  singleImage?: ImageAttributes
  singleImageWrapperStyle?: ImageWrapperStyle
  imageComponent?: ReactElement
  hideImage?: boolean
  reverseDefault?: string
}

const SalesSection: React.FC<React.PropsWithChildren<SalesSectionProps>> = (props) => {
  const {
    headingText,
    bodyText,
    reverse,
    primaryButton,
    secondaryButton,
    images,
    singleImage,
    singleImageWrapperStyle,
    imageComponent,
    hideImage,
    reverseDefault,
  } = props

  const { isMobile } = useMatchBreakpoints()

  return (
    <Flex flexDirection="column">
      <Flex
        flexDirection={['row-reverse', null, null, reverse ? 'row-reverse' : 'row']}
        alignItems={['center', null, null, 'center']}
        justifyContent="center"
        style={{ gap: '5px' }}
      >
        {!hideImage ? (
          <Flex flex="2" mb={['24px', null, null, '0']} mr={[null, null, null, !reverse && '41px']} position="relative">
            {imageComponent ? (
              <Flex flex="1" justifyContent={['flex-end', null, null, reverse ? 'flex-end' : 'flex-start']}>
                {imageComponent}
              </Flex>
            ) : images ? (
              <CompositeImage {...images} />
            ) : singleImage ? (
              // <div style={{ display: 'flex', flex: 1, position: 'relative' }}>
              //   <ImageWrapper {...singleImageWrapperStyle}>
              //     <img {...singleImage} style={{ maxWidth: '100%', maxHeight: '100%' }} alt="img" />
              //   </ImageWrapper>
              // </div>
              <FilledImageFitOnTop
                src={singleImage.src}
                alt={singleImage.alt}
                {...singleImageWrapperStyle}
                borderRadius="32px"
              />
            ) : (
              <></>
            )}
          </Flex>
        ) : (
          <></>
        )}
        <Flex
          flexDirection="column"
          flex="3"
          // ml={[null, null, null, reverse && '64px']}
          alignSelf={['flex-start', null, null, 'center']}
          justifySelf="flex-start"
        >
          {Array.isArray(headingText) ? (
            headingText.map((heading, index) => (
              <ColoredWordHeading
                marginBottom={index !== headingText.length - 1 ? 0 : null}
                text={heading}
                key={heading}
              />
            ))
          ) : (
            <ColoredWordHeading text={headingText} />
          )}
          <Text color="mainPageTextSubtle" bold={!isMobile} mb="24px">
            {bodyText}
          </Text>
          <Flex>
            {primaryButton.notShow ? (
              <></>
            ) : (
              <Button mr="16px">
                {primaryButton.external ? (
                  <Link external href={primaryButton.to}>
                    <Text color="card" bold fontSize="16px">
                      {primaryButton.text}
                    </Text>
                  </Link>
                ) : primaryButton.to ? (
                  <RouterLink to={primaryButton.to}>
                    <Text color="card" bold fontSize="16px">
                      {primaryButton.text}
                    </Text>
                  </RouterLink>
                ) : (
                  <Text color="card" bold fontSize="16px">
                    {primaryButton.text}
                  </Text>
                )}
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default SalesSection
