import { ReactNode } from 'react'
import styled from 'styled-components'
import { Card, CardBody, Box, CardProps } from '@pancakeswap/uikit'

const StyledCard = styled(Card)<{ background: string; rotation?: string }>`
  height: fit-content;
  // padding: 1px 1px 4px 1px;
  box-sizing: border-box;
  // border-radius: 14px;

  ${({ theme }) => theme.mediaQueries.md} {
    ${({ rotation }) => (rotation ? `transform: rotate(${rotation});` : '')}
  }
`

const IconWrapper = styled(Box)<{ rotation?: string; top?: number; right?: number }>`
  position: absolute;
  top: ${({ top }) => top || 24}px;
  right: ${({ right }) => right || 24}px;

  ${({ theme }) => theme.mediaQueries.md} {
    ${({ rotation }) => (rotation ? `transform: rotate(${rotation});` : '')}
  }
`

interface IconCardProps extends IconCardData, CardProps {
  children: ReactNode
}

export interface IconCardData {
  icon: ReactNode
  background?: string
  borderColor?: string
  rotation?: string
  top?: number
  right?: number
}

const IconCard: React.FC<React.PropsWithChildren<IconCardProps>> = ({
  icon,
  background,
  borderColor,
  rotation,
  top,
  right,
  children,
  ...props
}) => {
  return (
    <StyledCard
      background={background}
      borderBackground={borderColor}
      rotation={rotation}
      // top={top}
      // right={right}
      {...props}
    >
      <CardBody>
        <IconWrapper rotation={rotation} top={top} right={right}>
          {icon}
        </IconWrapper>
        {children}
      </CardBody>
    </StyledCard>
  )
}

export default IconCard
