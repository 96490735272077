import {
  Flex,
  Text,
  Button,
  Link,
  NextLinkFromReactRouter as RouterLink,
  useMatchBreakpoints,
} from '@pancakeswap/uikit'
import { ReactElement, ReactNode } from 'react'
import CompositeImage, { CompositeImageProps, ImageAttributes, ImageWrapperStyle } from './CompositeImage'
import ColoredWordHeading from './ColoredWordHeading'
import FilledImageFitOnTop from './FilledImage'

interface SwapSectionButton {
  to?: string
  text: string
  external: boolean
  notShow?: boolean
}

export interface SalesSectionProps {
  headingText: string | string[]
  bodyText: string
  reverse: boolean
  primaryButton: SwapSectionButton
  secondaryButton: SwapSectionButton
  images?: CompositeImageProps
  singleImage?: ImageAttributes
  singleImageWrapperStyle?: ImageWrapperStyle
  imageComponent?: ReactElement
  hideImage?: boolean
}

const SwapSection: React.FC<React.PropsWithChildren<SalesSectionProps>> = (props) => {
  const {
    headingText,
    bodyText,
    reverse,
    primaryButton,
    secondaryButton,
    images,
    singleImage,
    singleImageWrapperStyle,
    imageComponent,
    hideImage,
  } = props

  const { isMobile } = useMatchBreakpoints()

  return (
    <Flex flexDirection="column">
      <Flex
        flexDirection={['row', null, null, reverse ? 'row-reverse' : 'row']}
        alignItems={['flex-end', null, null, 'center']}
        justifyContent="center"
      >
        {!hideImage ? (
          <Flex flexDirection="column" flex="1" mr={[null, null, null, !reverse && '41px']}>
            {imageComponent ? (
              <div style={{ display: 'flex', flex: 1, justifyContent: reverse ? 'flex-end' : 'flex-start' }}>
                {imageComponent}
              </div>
            ) : images ? (
              <CompositeImage {...images} />
            ) : singleImage ? (
              // <div style={{ display: 'flex', flex: 1, position: 'relative' }}>
              //   <ImageWrapper {...singleImageWrapperStyle}>
              //     <img {...singleImage} style={{ maxWidth: '100%', maxHeight: '100%' }} alt="img" />
              //   </ImageWrapper>
              // </div>
              <FilledImageFitOnTop
                src={singleImage.src}
                alt={singleImage.alt}
                {...singleImageWrapperStyle}
                borderRadius="32px"
              />
            ) : (
              <></>
            )}
          </Flex>
        ) : (
          <></>
        )}
        <Flex
          flexDirection="column"
          flex="1"
          // ml={[null, null, null, reverse && '64px']}
          alignSelf={['flex-start', null, null, 'center']}
          justifySelf="flex-start"
          p="24px"
        >
          {Array.isArray(headingText) ? (
            headingText.map((heading, index) => (
              <ColoredWordHeading
                marginBottom={index !== headingText.length - 1 ? 0 : null}
                text={heading}
                key={heading}
              />
            ))
          ) : (
            <ColoredWordHeading text={headingText} />
          )}
          <Text color="mainPageTextSubtle" bold={!isMobile} mb="24px">
            {bodyText}
          </Text>
          <Flex>
            <Button mr="16px">
              {primaryButton.external ? (
                <Link external href={primaryButton.to}>
                  <Text color="card" bold fontSize="16px">
                    {primaryButton.text}
                  </Text>
                </Link>
              ) : (
                <RouterLink to={primaryButton.to}>
                  <Text color="card" bold fontSize="16px">
                    {primaryButton.text}
                  </Text>
                </RouterLink>
              )}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default SwapSection
