import { Heading, Flex, Text, Skeleton, ChartCardIcon, CommunityIcon, SwapIcon } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import useTheme from 'hooks/useTheme'
import { formatLocalisedCompactNumber } from '@pancakeswap/utils/formatBalance'
import useSWRImmutable from 'swr/immutable'
import IconCard, { IconCardData } from '../IconCard'
import StatCardContent from './StatCardContent'
import GradientLogo from '../GradientLogoSvg'
import useGetTotalIndex from './useGetTotalIndex'

const Stats = () => {
  const { t } = useTranslation()
  const { theme } = useTheme()

  const { isLoading, totalTransactions, totalNewUser } = useGetTotalIndex()

  const { data: tvl } = useSWRImmutable('tvl')
  const { data: txCount } = useSWRImmutable('totalTx30Days')
  const { data: addressCount } = useSWRImmutable('addressCount30Days')
  const trades = isLoading ? '--,---' : new Intl.NumberFormat().format(totalTransactions)
  const users = isLoading ? '--,---' : new Intl.NumberFormat().format(totalNewUser)
  const tvlString = tvl ? formatLocalisedCompactNumber(tvl) : '-'

  const tvlText = t('And those users are now entrusting the platform with over $%tvl% in funds.', { tvl: tvlString })
  const [entrusting, inFunds] = tvlText.split(tvlString)

  const UsersCardData: IconCardData = {
    icon: <CommunityIcon width="40px" />,
    borderColor: '#9695FF',
    background: 'none',
    top: 20,
  }

  const TradesCardData: IconCardData = {
    icon: <SwapIcon width="45px" />,
    borderColor: '#2FBEDD',
    background: 'none',
    top: 8,
  }

  const StakedCardData: IconCardData = {
    icon: <ChartCardIcon width="36px" />,
    borderColor: '#CA995D',
    background: 'none',
  }

  return (
    <Flex justifyContent="center" alignItems="center" flexDirection="column">
      <GradientLogo height="66px" width="66px" mb="24px" />
      {/* <Heading textAlign="center" scale="xl">
        {t('Used by millions.')}
      </Heading> */}
      <Heading textAlign="center" scale="xl" mb="10px">
        {t('The Speed, Safety, and Privacy Trident.')}
      </Heading>
      <Text textAlign="center" color="#64958C" maxWidth={['283px', null, null, '100%']}>
        {t(
          'Powered by the Nautilus’ modular architecture and zk-technology, PoseiSwap enables safe and smooth trading at blazing speeds. Enjoy a privacy-preserving platform with throughput ready for real-world application.',
        )}
      </Text>
      {/* <Flex flexWrap="wrap" maxWidth={['283px', null, null, '100%']}>
        <Text display="inline" textAlign="center" color="#64958C" mb="20px">
          {entrusting}
          <>{tvl ? <>{tvlString}</> : <Skeleton display="inline-block" height={16} width={70} mt="2px" />}</>
          {inFunds}
        </Text>
      </Flex> */}

      {/* <Text textAlign="center" color="#64958C" bold mb="32px">
        {t('Will you join them?')}
      </Text> */}

      <Flex
        maxWidth="100%"
        flexDirection={['column', null, null, 'row']}
        style={{ columnGap: '100px', rowGap: '16px' }}
        justifyContent="center"
        mt={[16, null, null, 30]}
      >
        <IconCard {...UsersCardData}>
          <StatCardContent
            headingText={t('%users% users', { users })}
            bodyText={t('in the last 30 days')}
            highlightColor="#9695FF"
          />
        </IconCard>
        <IconCard {...TradesCardData}>
          <StatCardContent
            headingText={t('%trades% trades', { trades })}
            bodyText={t('made in the last 30 days')}
            highlightColor="#2FBEDD"
          />
        </IconCard>
        {/* <IconCard {...StakedCardData}> */}
        {/*  <StatCardContent */}
        {/*    headingText={t('$%tvl% staked', { tvl: tvlString })} */}
        {/*    bodyText={t('Total Value Locked')} */}
        {/*    highlightColor="#CA995D" */}
        {/*  /> */}
        {/* </IconCard> */}
      </Flex>
    </Flex>
  )
}

export default Stats
