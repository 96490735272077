import styled from 'styled-components'
import { Flex, Heading, Text, Link, useMatchBreakpoints, OpenNewIcon, Image } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import ConnectWalletButton from 'components/ConnectWalletButton'
import Container from 'components/Layout/Container'
import { useAccount } from 'wagmi'
import SunburstSvg from './SunburstSvg'
import CompositeImage from './CompositeImage'

const BgWrapper = styled.div`
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
`

const StyledSunburst = styled(SunburstSvg)`
  height: 350%;
  width: 350%;

  ${({ theme }) => theme.mediaQueries.xl} {
    height: 400%;
    width: 400%;
  }
`

const Wrapper = styled(Flex)`
  z-index: 1;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  flex: 1;
  margin-right: 10px;
`

const FloatingPancakesWrapper = styled(Container)`
  overflow: visible;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;

  ${({ theme }) => theme.mediaQueries.md} {
    visibility: visible;
  }
`

const TopLeftImgWrapper = styled(Flex)`
  position: absolute;
  left: 0;
  top: 0;
`

const StatueImgWrapper = styled(Flex)`
  flex: 1;
  position: relative;
`

const topLeftImage = {
  path: '/images/home/flying-pancakes/',
  attributes: [
    { src: '1-bottom', alt: 'PoseiSwap flying on the bottom' },
    { src: '1-left', alt: 'PoseiSwap flying on the left' },
    { src: '1-top', alt: 'PoseiSwap flying on the top' },
  ],
}

const bottomRightImage = {
  path: '/images/home/flying-pancakes/',
  attributes: [
    { src: '2-bottom', alt: 'PoseiSwap flying on the bottom' },
    { src: '2-top', alt: 'PoseiSwap flying on the top' },
    { src: '2-right', alt: 'PoseiSwap flying on the right' },
  ],
}

const OuterWrapper = styled(Flex)`
  display: flex;
  justify-content: space-between;
  overflow: visible;
`

const RoundImage = styled.img`
  width: 40%;
  border-radius: 50%;
  max-width: 320px;
  align-self: center;
`

const Footer = () => {
  const { t } = useTranslation()
  const { address: account } = useAccount()
  const { isTablet, isDesktop } = useMatchBreakpoints()

  return (
    <>
      {/* <BgWrapper>
        <Flex alignItems="center" justifyContent="center" width="100%" height="100%">
          <StyledSunburst />
        </Flex>
      </BgWrapper> */}
      <OuterWrapper>
        <Wrapper>
          <Heading mb="24px" scale="xl" color="white" fontWeight={1000}>
            {t('The Patheon of Trading')}
          </Heading>
          <Text fontWeight={600} fontSize={[12, null, null, 18]} color="#64958C" mb='24px'>
            {t('PoseiSwap aims to support more than just Web3 assets. Besides It aims to unite DeFi and TradFi, allowing Web3 and Web2.')}
          </Text>
          {/* <Text mb="24px" fontWeight={600} fontSize={[12, null, null, 18]} color="#64958C">
            {t('No registration needed.')}
          </Text> */}

          {/* {!account && <ConnectWalletButton mb="24px" />} */}
          {!account && <ConnectWalletButton />}
          {/* <Link external href="https://docs.pancakeswap.finance/" color="primaryText" fontWeight={600} fontSize={18}>
          {t('Learn how to start')}
          <OpenNewIcon color="primaryText" ml="8px" />
        </Link> */}
        </Wrapper>

        <RoundImage src="images/weapon3.png" alt="png" />
      </OuterWrapper>
    </>
  )
}

export default Footer
