import styled from 'styled-components'
import { ImageAttributes, ImageWrapperStyle } from './CompositeImage'

const FilledImageFitOnTop = styled.img<{
  height: number
  width?: number
  borderRadius?: string
}>`
  ${({ theme }) => theme.mediaQueries.lg} {
    margin: 0;
  }

  position: relative;
  flex: 1;
  object-fit: cover;
  object-position: top center;
  max-width: ${({ width }) => `${width}px` ?? '100%'};
  max-height: ${({ height }) => height}px;
  border-radius: ${({ borderRadius }) => borderRadius ?? '0'};
`

export default FilledImageFitOnTop
