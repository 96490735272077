import { Box, Button, Flex, Heading, NextLinkFromReactRouter, Text, useMatchBreakpoints } from '@pancakeswap/uikit'
import { useAccount } from 'wagmi'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useTranslation } from '@pancakeswap/localization'
import Image from 'next/legacy/image'
import { ChainId } from '@pancakeswap/sdk'
import { useActiveChainId } from 'hooks/useActiveChainId'
import styled, { keyframes } from 'styled-components'
// import bunnyImage from '../../../../public/images/home/lunar-bunny/astronaut-bunny.png'
// import CompositeImage, { CompositeImageProps } from './CompositeImage'
// import { SlideSvgDark, SlideSvgLight } from './SlideSvg'
import useTheme from '../../../hooks/useTheme'
import RoundImageWithBorder from './RoundImageWithBorder'

const flyingAnim = () => keyframes`
  from {
    transform: translate(0,  0px);
  }
  50% {
    transform: translate(-5px, -5px);
  }
  to {
    transform: translate(0, 0px);
  }
`

const fading = () => keyframes`
  from {
    opacity: 0.9;
  }
  50% {
    opacity: 0.1;
  }
  to {
    opacity: 0.9;
  }
`

const BgWrapper = styled.div`
  z-index: -1;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0px;
  left: 0px;
`

const InnerWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: -3px;
`

const PoseidonStatueWrapper = styled.div`
  position: absolute;
  width: 200%;
  margin-top: -347px;
  margin-left: -200px;
`

const BunnyWrapper = styled.div`
  width: 100%;
  animation: ${flyingAnim} 3.5s ease-in-out infinite;
  will-change: transform;
  > span {
    overflow: visible !important; // make sure the next-image pre-build blur image not be cropped
  }
`

const StarsWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  & :nth-child(2) {
    animation: ${fading} 2s ease-in-out infinite;
    animation-delay: 1s;
  }

  & :nth-child(3) {
    animation: ${fading} 5s ease-in-out infinite;
    animation-delay: 0.66s;
  }

  & :nth-child(4) {
    animation: ${fading} 2.5s ease-in-out infinite;
    animation-delay: 0.33s;
  }
`

// const starsImage: CompositeImageProps = {
//   path: '/images/home/lunar-bunny/',
//   attributes: [
//     { src: 'star-l', alt: '3D Star' },
//     { src: 'star-r', alt: '3D Star' },
//     { src: 'star-top-r', alt: '3D Star' },
//   ],
// }

const Hero = () => {
  const { t } = useTranslation()
  const { address: account } = useAccount()
  const { chainId } = useActiveChainId()
  const { theme } = useTheme()
  const { isMobile } = useMatchBreakpoints()

  return (
    <>
      <style jsx global>
        {`
          .slide-svg-dark {
            display: none;
          }
          .slide-svg-light {
            display: block;
          }
          [data-theme='dark'] .slide-svg-dark {
            display: block;
          }
          [data-theme='dark'] .slide-svg-light {
            display: none;
          }
        `}
      </style>
      {/* <BgWrapper>
        <InnerWrapper>
          <SlideSvgDark className="slide-svg-dark" width="100%" />
          <SlideSvgLight className="slide-svg-light" width="100%" />
        </InnerWrapper>
      </BgWrapper> */}
      <Flex
        position="relative"
        flexDirection="row"
        alignItems={['center', null, null, 'center']}
        justifyContent="center"
        mt={!(account && chainId === ChainId.BSC) ? '0px' : ['175px', '175px', '150px', '75px', '50px']}
        id="homepage-hero"
        background={
          theme.isDark ? 'linear-gradient(267.79deg, rgba(62, 78, 225, 0.3) 0%, rgba(1, 163, 143, 0.3) 100%)' : 'none'
        }
        padding={['20px 15px', null, '30px', '60px']}
        borderRadius={8}
        style={{ backdropFilter: 'blur(25px)', WebkitBackdropFilter: 'blur(25px)' }}
      >
        <Flex flex="3" flexDirection="column" mr="5px">
          <Heading scale={isMobile ? 'lg' : 'xl'} color="contrast" mb={['10px', null, null, '24px']}>
            {t('Next Generation DeFi')}
          </Heading>
          <Text color="white" bold={!isMobile} mb="24px" fontSize={['11px', null, '13px', '24px']}>
            {t('Swap, stake, and earn on the only platform preparing for Web3 and real-world assets.')}
          </Text>
          {/* <Heading scale="md" mb="24px"> */}
          {/*  {t('Trade, earn, and win crypto on the most popular decentralized platform in the galaxy.')} */}
          {/* </Heading> */}
          <Flex>
            {!account && (
              <ConnectWalletButton mr="8px" style={isMobile ? { fontSize: '13px', padding: '7px' } : null}>
                {t('Connect Wallet')}
              </ConnectWalletButton>
            )}
            <NextLinkFromReactRouter to="/swap">
              <Button
                variant={!account ? 'secondary' : 'primary'}
                mb={0}
                style={isMobile ? { fontSize: '13px', padding: '7px' } : null}
              >
                {t('Trade Now')}
              </Button>
            </NextLinkFromReactRouter>
          </Flex>
        </Flex>
        <Flex
          height="100%"
          width="100%"
          flex="2"
          // mb={['24px', null, null, '0']}
          position="relative"
          justifyContent="flex-end"
          ml="10px"
        >
          {/* <BunnyWrapper>
            <Image src={bunnyImage} priority placeholder="blur" alt={t('Lunar bunny')} />
          </BunnyWrapper> */}
          {/* <StarsWrapper> */}
          {/*   <CompositeImage {...starsImage} /> */}
          {/* </StarsWrapper> */}

          <RoundImageWithBorder
            imageWrapperStyle={{ width: 279, height: 279 }}
            image={{ src: '/images/poseidon4.png', alt: 'image' }}
          />
        </Flex>
      </Flex>
    </>
  )
}

export default Hero
